import React from 'react';
import ReactGA from 'react-ga4';
import ChangePassword from '../Components/User/ChangePassword';
import CommonHeader from '../Components/Headers/CommonHeader';
import Modal from 'react-modal';
import Footer from '../Components/Home/Footer';

export default function Reset() {
  ReactGA.send({
    hitType: 'pageview',
    page: '/Reset',
    title: 'Reset password',
  });
//
  const customStyles = {
    content: {
      position: 'relative',
      top: '50%',
      transform: 'translateY(-57%)',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '600px',
      background: 'transparent',
      boxSizing: 'border-box',
      border: null,
      padding: null,
    },
  };

  return (
    <>
      <CommonHeader />
      <Modal isOpen={true} ariaHideApp={false} style={customStyles} shouldFocusAfterRender={false}>
        <ChangePassword />
      </Modal>
      <Footer />
    </>
  );
}
