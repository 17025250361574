import CountryCodes from './CountryCodes';

const specialWhiteList = ['tseeley4@comcast.net', 'drpathak125@gmail.com', 'ipsita.bst@gmail.com'];

const blacklistDomains = [
  '126',
  '163',
  '139',
  '1623',
  'outlook',
  'hotmail',
  'yahoo',
  'gmail',
  'qq',
  'vip.qq.com', // leave the .com for multi-level domains
  'msn',
  'me',
  'icloud',
  'live',
  'foxmail',
  'aol',
  'googlemail',
  'gmx',
  'protonmail',
  'sina',
  'e-mail.edu.pl',
  '153fdd',
  'ceeun',
  'tthy.site',
];

function validateEmailBusiness(email) {
  const whitelistDomains = [];
  const domain = email.split('@')[1];
  const sld = domain ? domain.split('.')[0] : null;
  // white/black list based validations
  // white
  if (specialWhiteList.includes(email)) {
    return true;
  }
  if (whitelistDomains.includes(sld)) {
    return true;
  }
  // black
  // for single word domains
  if (blacklistDomains.includes(sld)) {
    return false;
  }
  // for multi word domains
  if (blacklistDomains.includes(domain)) {
    return false;
  }
  // passed all validation tests, return true
  return true;
}

function validateEmailAcademic(email) {
  const allowedTld = ['edu', 'gov', 'org', 'eu', ...CountryCodes];
  const whitelistDomains = ['disgenet'];
  const domain = email?.split('@')[1];
  const sld = domain ? domain.split('.')[0] : null;
  const tld = domain?.split('.').slice(-2).join('.');
  const simpleTld = domain?.split('.').pop();
  // white/black list based validations
  // white
  if (specialWhiteList.includes(email)) {
    return true;
  }
  if (whitelistDomains.includes(sld)) {
    return true;
  }
  // black
  // for single word domains
  if (blacklistDomains.includes(sld)) {
    return false;
  }
  // for multi word domains
  if (blacklistDomains.includes(domain)) {
    return false;
  }
  // Check if the full TLD (like "edu.co") or the simple TLD (like "co") is allowed
  if (allowedTld.includes(tld) || allowedTld.includes(simpleTld)) {
    return true;
  }
  // Check if TLD starts with "edu." followed by lowercase letters
  if (/^edu\.[a-z]+$/.test(tld)) {
    return true;
  }
  return false;
}

function validateEmail(email, isAcademic = false) {
  const cleanedEmail = email?.trim()?.toLowerCase();
  // check email format
  const isValidFormat = cleanedEmail?.match(
    /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/,
  );
  // check specific inclusions/exclusions
  if (!isValidFormat) {
    return false;
  }
  // check specific inclusions/exclusions
  const isValidDomain = isAcademic
    ? validateEmailAcademic(cleanedEmail)
    : validateEmailBusiness(cleanedEmail);
  // return final result
  return isValidDomain;
}

function validateNotEmpty(inputValue) {
  return inputValue?.trim() !== '';
}

export { validateEmail, validateNotEmpty };
